<template>
  <div></div>
</template>

<script>
export default {
  name: 'NotFound',
  data: () => ({}),
}
</script>
